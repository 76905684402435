/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ReactNode } from "react"

import { cn } from "utils"

import styles from "./Pill.module.scss"

type TProps = {
  children: ReactNode
  className?: string
  big?: boolean
  type:
    | "labelRed"
    | "labelYellow"
    | "labelGreen"
    | "labelBlue"
    | "labelGray"
    | "labelBlack"
    | undefined
}

export default function Pill(props: TProps): JSX.Element {
  return (
    <span
      className={cn([
        styles.Pill,
        "h-5 rounded-xl text-xs",
        props.big && "h-8 rounded-2xl text-sm",
        props.type === "labelRed" && styles.PillRed,
        props.type === "labelYellow" && styles.PillYellow,
        props.type === "labelGreen" && styles.PillGreen,
        props.type === "labelBlue" && styles.PillBlue,
        props.type === "labelGray" && styles.PillGray,
        props.type === "labelBlack" && styles.PillBlack,
        props.className,
      ])}
    >
      {props.children}
    </span>
  )
}
