import { FC } from "react"

import Button from "@/components/elements/Button"
import { ChevronDown as ChevronDownIcon } from "assets/icons"
import { cn } from "utils"

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "./dropdownMenu"

type Separator = {
  type: "separator"
}

type MenuOption = {
  type?: "default" | "destructive"
  label: string
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  icon?: React.ReactNode
  disabled?: boolean
}

export type MenuItem = Separator | MenuOption

interface Props {
  title?: string
  options: MenuItem[]
  children?: React.ReactNode
}

const Menu: FC<Props> = ({ title, options, children }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {children ?? (
          <Button className="shrink-0">
            {title}
            <ChevronDownIcon className="icon ml-2" />
          </Button>
        )}
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align="end"
        className="bg-white -mt-[2px]"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <DropdownMenuGroup>
          {options.map((option, index) => {
            if (option.type === "separator") {
              return <DropdownMenuSeparator key={index} />
            }

            return (
              <DropdownMenuItem
                key={option.label}
                className={cn(
                  "flex gap-2 text-gray-900",
                  option.type === "destructive" &&
                    "text-tomato focus:bg-roseCream focus:text-tomato"
                )}
                onClick={(e) => {
                  e.stopPropagation()
                  !option.disabled && option.onClick(e)
                }}
                disabled={option.disabled}
              >
                {option.icon}
                {option.label}
              </DropdownMenuItem>
            )
          })}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default Menu
