import { useEffect, useState } from "react"

import {
  TEmployeeForTable,
  useEmployee,
  useEmployeesForTableInfinite,
} from "hooks/data"
import { EUserRoles } from "src/constants"
import { IUser } from "types"

import Combobox from "../forms/Combobox"

type Props = {
  onChange: (value?: number, user?: IUser) => void
  value?: number
  searchName?: string
  searchRole?: keyof typeof EUserRoles
  getInitialUser?: (value: IUser) => void
  label?: string
  hasReset?: boolean
}

const ComboboxEmployee = ({
  onChange,
  value,
  searchName: initialSearchTerm,
  // getInitialUser,
  label,
  hasReset,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

  const { data } = useEmployeesForTableInfinite(
    {
      $top: 50,
      $orderby: `EmployeeName asc`,
      searchterm: searchTerm,
    },
    {
      staleTime: 0,
    }
  )

  const [fetchedEmployees, setFetchedEmployees] = useState<
    {
      primary: TEmployeeForTable["EmployeeName"]
      value: TEmployeeForTable["ID"]
    }[]
  >([])

  useEffect(() => {
    const fetchedEmployeesOnPage = data?.pages
      .map((page) =>
        page.data.map((employee) => ({
          primary: employee.EmployeeName,
          value: employee.ID,
        }))
      )
      .flat()
    setFetchedEmployees(fetchedEmployeesOnPage || [])
  }, [data])

  // Check if the selected employee is in the list of available employees.
  // If no, only then fetch him and add to the list.
  const empoyeeInList = fetchedEmployees?.some(
    (option) => option.value === value
  )

  const { data: employeeData } = useEmployee(!empoyeeInList ? value : undefined)

  useEffect(() => {
    if (!value || empoyeeInList || !employeeData) {
      return
    }

    const selectedEmployeeData = {
      primary: `${employeeData.Vorname} ${employeeData.Nachname}`,
      value: employeeData.MitarbeiterID,
    }

    setFetchedEmployees((prev) => {
      const exists = prev.some((option) => option.value === value)

      if (exists) {
        return [
          selectedEmployeeData,
          ...prev.filter((option) => option.value !== value),
        ]
      } else {
        return [selectedEmployeeData, ...prev]
      }
    })
  }, [employeeData, value, empoyeeInList])

  const handleChange = (optionValue?: number) => {
    // const selectedUser = usersQuery.data?.find(
    //   ({ BenutzerID }) => BenutzerID === optionValue
    // )
    onChange(optionValue)

    if (optionValue === undefined) {
      setSearchTerm("")
    }
  }

  const initialValue = fetchedEmployees?.find(
    (option) => option.value === value
  )

  // useEffect(() => {
  //   if (isSuccess && value) {
  //     const selectedUser = usersQuery.data?.find(
  //       ({ BenutzerID }) => BenutzerID === value
  //     )
  //     getInitialUser && selectedUser && getInitialUser(selectedUser)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <Combobox
      showReset={!!hasReset}
      label={label}
      onSearchTermChange={setSearchTerm}
      options={fetchedEmployees}
      onChange={(option) => {
        handleChange(option?.value)
      }}
      defaultOption={initialValue}
    />
  )
}

export default ComboboxEmployee
